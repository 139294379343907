.storyPage {
    margin: 20px 60px;
    background-color: rgb(242 242 242);
    border-radius: 10px;
    box-shadow: grey 2px 3px 8px 0px;
    padding: 20px 40px;
} 

.stories{
    width: 60%;
    margin: 20px 0px;
}

.textArea {
    border-radius: 10px;
    resize: none;
    padding: 10px 20px
}

.writeComment{
    margin-top: 60px;
}

.sendBtn{
    background-color: #C25E00;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    margin: 10px 0px;
    cursor: pointer;
    transition: 0.3s;
}

.sendBtn:hover{
    background-color: #a85100;
}