@import "../../style/colors.scss";

.create-btn {
  background-color: #c25e00;
  border-radius: 10px;
  padding: 10px;
  width: 120px;
  text-align: center;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  transition: 0.4s;
}

.create-btn:hover {
  background-color: $primary;
}