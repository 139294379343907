.story {
    height: 200px;
    width: 450px;
    background-color: rgb(242 242 242);
    border-radius: 10px;
    box-shadow: grey 2px 3px 8px 0px;
    margin: 40px;
} 

.story:hover {
    cursor: pointer;
}

.storyHeader{
    margin: 20px;
    display: flex;
    justify-content: space-between;
}
.storyPreContent {
    margin: 0 20px;
}
.storyDetail {
    margin: 20px;
    display: flex;
    justify-content: space-between;
}

.favorite-icon {
    color: red;
} 

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}