.storyList {
    display: grid;
    grid-template-columns: 9fr 1fr;
    margin-bottom: 10px;
}

.storyList-content {
    width: 75%;
    display: grid;
    grid-template-columns: 5fr 5fr;
    gap: 25px;
}