.header-decor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 11%; 
  background: $primary;
  z-index: 2;
} 

.nav {
  position: absolute;
  display: flex;
  justify-content: space-around;
  color: $primary;
  font-size: 1rem;
  width: 60%;
  font-weight: 600;
  align-items: center;
  z-index: 9;
  margin-top: 4%;
  background-color: $background;

  margin-right: 20%;
  margin-left: 20%;

  padding: 0 2%;
  border-radius: 10px;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.477)
}

.nav .nav-link {
cursor: pointer;
transition: all 0.2s ease-in-out;
}

.nav .nav-link:hover {
  color: $primary-dark;
  text-decoration: none;
}

.content {
  margin-top: 12%;
  margin-left: 5%;
  margin-right: 5%;
}