@import "./style/colors.scss";
@import "./components/custom/Frame/Frame.scss";
@import "./components/custom/Story/Story.scss";
@import "./pages/StoryList/StoryList.scss";
@import "./pages/Story/StoryPage.css";
@import "./pages/Login/Login.css";
@import "./pages/SignUp/SignUp.scss";
@import "./components/custom/SearchBar/SearchBar.scss";
@import "./pages/NewStory/CreateStory.scss";


body {
  margin: 0;
  padding: 0;
  background-color: #F2F2F2;
}

/* .App{
  display: flex;
  flex-direction: column;
  align-items: center;
} */
