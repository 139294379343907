@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.login {
    width: 100vw;
    height: 100vh;
    background: url('../../assets/images/pexels-leah-kelley-952594.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.loginMask {
    width: 100vw;
    height: 100vh;
    background-color: #c25e0073;
    position: absolute;
}

.login-content {
    position: absolute;
    display: flex;
    margin: 10% 100px;
}

.loginTitle {
    text-align: center;
    font-size: 32px;
    color: #c25e00;
    font-family: 'Great Vibes', cursive;
}

.loginFormPanel {
    padding: 10px;
    width: 550px;
    height: 500px;
    border-radius: 10px;
    background-color: rgb(242 242 242);
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form>input {
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    margin: 10px 0;
}

.requiredCheckox {
    display: flex;
    align-items: center;
    margin: 10px 5px;
    font-size: 12px;
}
.requiredCheckox > p {
    margin-left: 10px;
}


.signUp-btn {
    background-color: #c25e00;
    border-radius: 10px;
    padding: 10px;
    width: 120px;
    text-align: center;
    color: white;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.4s;
}

.loginBtn:hover {
    background-color: #e67002;
}

.signUpBtn {
    border: #c25e00 1px solid;
    border-radius: 10px;
    color:#c25e00;
    padding: 10px;
    width: 120px;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
    transition: 0.4s;
}

.signUpBtn:hover {
    background-color: #e67002;
    color: white;
    border-color: #e67002;
}

.randomSzoveg {
    color: white;
    margin-left: 120px;
    font-size: 24px;
}

.qoutes {
    font-size: 32px;
    font-family: 'Caveat', cursive;
}